<template>
  <div>
    <section class="section is-main-section">
      <h1 class="title">ບັນຊີ ລາຍຮັບ-ລາຍຈ່າຍ</h1>
      <div class="columns panel">
        <div class="column">
          <b-field label="ຈາກວັນທີ" horizontal>
            <b-datepicker
              v-model="fromdate"
              icon="calendar-today"
              @input="changeFromDate"
              :locale="this.getDateLocale"
            />
          </b-field>
        </div>
        <div class="column">
          <b-field label="ຫາວັນທີ" horizontal>
            <b-datepicker
              v-model="todate"
              icon="calendar-today"
              @input="changeTodate"
              :locale="this.getDateLocale"
            />
          </b-field>
        </div>
        <div class="column" v-if="user.role === 'ADMIN'">
          <b-field label="ສາຂາ" horizontal>
            <multiselect
              v-model="branch"
              :options="branches"
              track-by="id"
              :custom-label="branchOptions"
              label="name"
              placeholder="ສາຂາທັງໝົດ"
              select-label=""
              selected-label=""
              deselect-label=""
            />
          </b-field>
        </div>
        <div class="column is-2">
          <div class="buttons">
            <b-button class="is-info" @click="get()" icon-left="magnify">
            </b-button>
            <b-button
              class="is-success"
              @click="downloadExcel()"
              icon-left="download"
            >
            </b-button>
          </div>
        </div>
      </div>

      <div class="columns">
        <div class="column is-7">
          <div v-if="model" class="panel is-success">
            <div class="panel-heading">ລົງບັນຊີໃໝ່</div>
            <div class="" style="padding: 1em">
              <div class="columns">
                <div class="column is-3">
                  <b-field label="ວັນທີ">
                    <b-datepicker
                      v-model="model.accounting_date"
                      icon="calendar-today"
                      :locale="this.getDateLocale"
                    />
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-field label="">
                    <radio-picker
                      v-model="model.type"
                      :options="{
                        cr: 'ລາຍຮັບ (+)',
                        dr: 'ລາຍຈ່າຍ (-)',
                      }"
                    />
                  </b-field>
                </div>
                <div class="column">
                  <b-field v-if="model.type === 'dr'" label="" :message="dr">
                    <b-input
                      v-model="model.dr"
                      type="number"
                      placeholder="ຈຳນວນເງິນ"
                      :min="0"
                      @focus="onFocusDR"
                      @blur="onBlurDR"
                    />
                    <p v-if="model.ccy === 'LAK'" class="control">
                      <span class="button is-static">x1000</span>
                    </p>
                  </b-field>
                  <b-field v-if="model.type === 'cr'" label="" :message="cr">
                    <b-input
                      v-model="model.cr"
                      type="number"
                      placeholder="ຈຳນວນເງິນ"
                      :min="0"
                      @focus="onFocusCR"
                      @blur="onBlurCR"
                    >
                    </b-input>
                    <p v-if="model.ccy === 'LAK'" class="control">
                      <span class="button is-static">x1000</span>
                    </p>
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-field label="">
                    <radio-picker
                      v-model="model.ccy"
                      :options="{
                        LAK: 'ກີບ (LAK)',
                        THB: 'ບາດ (THB)',
                      }"
                    />
                  </b-field>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <b-field label="">
                    <b-input
                      v-model="model.remark"
                      placeholder="ໝາຍເຫດ"
                      type="textarea"
                      :rows="2"
                    />
                  </b-field>
                </div>
                <div class="column is-2">
                  <b-button
                    @click="save()"
                    icon-left="check"
                    class="is-primary"
                  >
                    ບັນທຶກ
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div v-if="models" class="panel is-info">
            <div class="panel-heading">ລວມ ລາຍຮັບ-ລາຍຈ່າຍ</div>
            <div class="" style="padding: 1em">
              <table class="table is-fullwidth">
                <thead>
                  <tr>
                    <th></th>
                    <th class="has-text-right">ກີບ</th>
                    <th class="has-text-right">ບາດ</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="is-info">
                    <th style="color: #00aa00">ລາຍຮັບ</th>
                    <td>
                      <p
                        style="color: #00aa00"
                        class="title is-6 has-text-right"
                      >
                        +{{ sum("cr", "LAK") | formatnumber }}
                      </p>
                    </td>
                    <td>
                      <p
                        style="color: #00aa00"
                        class="title is-6 has-text-right"
                      >
                        +{{ sum("cr", "THB") | formatnumber }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th style="color: #aa0000">ລາຍຈ່າຍ</th>
                    <td>
                      <p
                        style="color: #aa0000"
                        class="title is-6 has-text-right"
                      >
                        -{{ sum("dr", "LAK") | formatnumber }}
                      </p>
                    </td>
                    <td>
                      <p
                        style="color: #aa0000"
                        class="title is-6 has-text-right"
                      >
                        -{{ sum("dr", "THB") | formatnumber }}
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>ຍັງເຫຼືອ</th>
                    <td>
                      <p
                        v-if="sum('cr', 'LAK') - sum('dr', 'LAK') < 0"
                        style="color: #aa0000"
                        class="title is-4 has-text-right"
                      >
                        {{
                          (sum("cr", "LAK") - sum("dr", "LAK")) | formatnumber
                        }}
                      </p>
                      <p
                        v-if="sum('cr', 'LAK') - sum('dr', 'LAK') > 0"
                        style="color: #00aa00"
                        class="title is-4 has-text-right"
                      >
                        +{{
                          (sum("cr", "LAK") - sum("dr", "LAK")) | formatnumber
                        }}
                      </p>
                      <p
                        v-if="sum('cr', 'LAK') - sum('dr', 'LAK') === 0"
                        class="title is-4 has-text-right"
                      >
                        0
                      </p>
                    </td>
                    <td>
                      <p
                        v-if="sum('cr', 'THB') - sum('dr', 'THB') < 0"
                        style="color: #aa0000"
                        class="title is-4 has-text-right"
                      >
                        {{
                          (sum("cr", "THB") - sum("dr", "THB")) | formatnumber
                        }}
                      </p>
                      <p
                        v-if="sum('cr', 'THB') - sum('dr', 'THB') > 0"
                        style="color: #00aa00"
                        class="title is-4 has-text-right"
                      >
                        +{{
                          (sum("cr", "THB") - sum("dr", "THB")) | formatnumber
                        }}
                      </p>
                      <p
                        v-if="sum('cr', 'THB') - sum('dr', 'THB') === 0"
                        class="title is-4 has-text-right"
                      >
                        0
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="card">
        <b-table
          :loading="isLoading"
          :paginated="perPage < models.length"
          :per-page="perPage"
          :striped="true"
          :hoverable="true"
          :data="models"
          :current-page="currentPage"
          :pagination-rounded="true"
        >
          <b-table-column label="#" v-slot="props">
            {{ props.index * currentPage + 1 }}
          </b-table-column>
          <b-table-column
            label="ວັນທີບັນຊີ"
            field="accounting_date"
            sortable
            v-slot="props"
          >
            {{ props.row.accounting_date | datetime }}
          </b-table-column>
          <b-table-column
            label="ວັນທີບັນທຶກ"
            field="created_date"
            sortable
            v-slot="props"
          >
            {{ props.row.created_date | datetime }}
          </b-table-column>
          <b-table-column
            v-if="user.role === 'ADMIN'"
            label="ສາຂາ"
            field="username"
            v-slot="props"
          >
            {{ props.row.branch.code }}-{{ props.row.branch.name }}
          </b-table-column>
          <b-table-column label="ຜູ້ບັນທຶກ" field="username" v-slot="props">
            {{ props.row.user.username }}
          </b-table-column>
          <b-table-column label="ຈຳນວນເງິນ" field="cr" sortable v-slot="props">
            <p v-if="props.row.cr > 0" style="color: #00aa00">
              +{{ props.row.cr | formatnumber }} {{ props.row.ccy }}
            </p>
            <p v-if="props.row.dr > 0" style="color: #aa0000">
              -{{ props.row.dr | formatnumber }} {{ props.row.ccy }}
            </p>
          </b-table-column>

          <b-table-column label="ໝາຍເຫດ" field="remark" v-slot="props">
            {{ props.row.remark }}
          </b-table-column>
          <b-table-column
            custom-key="actions"
            cell-class="is-actions-cell"
            v-slot="props"
          >
            <div class="buttons is-right">
              <b-button
                class="is-small is-danger"
                @click="remove(props.row)"
                icon-left="trash-can"
              >
              </b-button>
            </div>
          </b-table-column>

          <empty-table v-if="models.length == 0" :isLoading="isLoading" />
        </b-table>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import EmptyTable from "../components/EmptyTable.vue";
import RadioPicker from "@/components/RadioPicker";
import moment from "moment";
import XLSX from "xlsx";
import Multiselect from "vue-multiselect";
import { get } from "vuex-pathify";
export default {
  name: "Ledgers",
  components: { EmptyTable, RadioPicker, Multiselect },
  watch: {
    models: function () {
      this.isLoading = false;
    },
  },
  computed: {
    user: get("user"),
    branches: get("branches"),
    cr: function () {
      return `+${this.formatnumber(this.model.cr * 1)}`;
    },
    dr: function () {
      return `-${this.formatnumber(this.model.dr * 1)}`;
    },
  },
  data() {
    return {
      branch: null,
      currentPage: 1,
      perPage: 20,
      isLoading: false,
      isShowModal: false,
      models: [],
      model: null,
      fromdate: new Date(),
      todate: new Date(),
    };
  },
  mounted() {
    this.get();
  },
  methods: {
    downloadExcel() {
      if (this.models.length < 1) return;

      //Create workBook
      const workBook = XLSX.utils.book_new();
      /* worksheet  data */
      if (this.models.id) {
        const ws_data_sheet = [];
        ws_data_sheet.push(["id", this.models.id]);

        /* create worksheet */
        const ws = XLSX.utils.aoa_to_sheet(ws_data_sheet);
        /* Add the worksheet to the workbook */
        XLSX.utils.book_append_sheet(workBook, ws, `ຂໍມູນລູກຄ້າ`);
      }
      const header = [
        "ວັນທີບັນຊີ",
        "ວັນທີບັນທຶກ",
        "ສາຂາ",
        "ໜີ້",
        "ມີ",
        "ສະກຸນເງິນ",
        "ຜູ້ບັນທຶກ",
        "ໝາຍເຫດ",
      ];

      const ws_data_sheet = [];
      ws_data_sheet.push(header);
      for (const item of this.models) {
        ws_data_sheet.push([
          moment(item.accounting_date).format("YYYY-MM-DD HH:mm:ss"),
          moment(item.created_date).format("YYYY-MM-DD HH:mm:ss"),
          item.branch.name,
          item.cr,
          item.dr,
          item.ccy,
          item.user.username,
          item.remark,
        ]);
      }
      /* create worksheet */
      const ws = XLSX.utils.aoa_to_sheet(ws_data_sheet);
      /* Add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(workBook, ws, `ledger`);

      /* Write workbook to file */
      const _date = moment(new Date()).format("YYYY_MM_DD_HHmmss");
      const _ub = `${this.user.branch.code}`;
      XLSX.writeFile(workBook, `LEDGER_FILE_${_ub}_${_date}.xlsx`);
    },
    init() {
      this.model = {
        accounting_date: new Date(),
        type: "cr",
        ccy: "LAK",
        cr: 0,
        dr: 0,
      };
    },
    sum(type, ccy) {
      let s = 0;
      for (let i = 0; i < this.models.length; i++) {
        const item = this.models[i];
        if (ccy === item.ccy) s += item[type] * 1;
      }
      return s;
    },
    remove(item) {
      this.$buefy.dialog.confirm({
        title: "ຢືນຢັນການລຶບຂ້ໍມູນ",
        message: "ທ່ານຕ້ອງການ <b>ລຶບ</b> ແທ້ບໍ?",
        confirmText: "ແມ່ນ, ລຶບ",
        cancelText: "ບໍ່",
        type: "is-danger",
        hasIcon: true,
        onConfirm: () => {
          axios.delete(`ledgers/delete/${item.id}`).then((r) => {
            console.log(r);
            this.get();
          });
        },
      });
    },
    onFocusDR() {
      this.model.dr = this.model.dr === 0 ? null : this.model.dr;
    },
    onFocusCR() {
      this.model.cr = this.model.cr === 0 ? null : this.model.cr;
    },
    onBlurDR() {
      this.model.dr = this.model.dr ? this.model.dr : 0;
    },
    onBlurCR() {
      this.model.cr = this.model.cr ? this.model.cr : 0;
    },
    closeModal() {
      this.isShowModal = false;
    },
    get() {
      this.model = {};
      this.isLoading = true;
      const fdate = moment(this.fromdate).format("YYYY-MM-DD");
      const tdate = moment(this.todate).format("YYYY-MM-DD");
      const bid = this.branch ? this.branch.id : 0;
      axios.get(`ledgers/gets/${fdate}/${tdate}/${bid}`).then((res) => {
        this.models = res;
        this.init();
      });
    },
    save() {
      if (this.model.dr <= 0 && this.model.cr <= 0) {
        this.$buefy.snackbar.open({
          message: "ຈຳນວນເງິນບໍ່ຖືກຕ້ອງ",
        });
        return;
      }
      this.loading = true;
      if (this.model.type === "cr") {
        this.model.dr = 0;
      }
      if (this.model.type === "dr") {
        this.model.cr = 0;
      }
      if (this.model.ccy === "LAK") {
        this.model.cr *= 1000;
        this.model.dr *= 1000;
      }
      axios.post("ledgers/add", this.model).then((res) => {
        console.log(res);
        this.get();
      });
    },
    changeFromDate(e) {
      this.fromdate = e;
    },
    changeTodate(e) {
      this.todate = e;
    },
    branchOptions({ code, name }) {
      return `${code} — ${name}`;
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
